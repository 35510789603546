import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import '../../assets/css/home_style.css';
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import ReactPlayer from 'react-player';


let sl_set = {};

export default function Home (props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [sliding, setSliding] = useState(false);
    const freelance_links_box = [
        {
            id: 1,
            link_title: 'Разработка сайтов',
        },
        {
            id: 2,
            link_title: 'Тексты',
        },
        {
            id: 3,
            link_title: '3D Графика',
        },
        {
            id: 4,
            link_title: 'Обучение и консультации',
        },
        {
            id: 5,
            link_title: 'Разработка игр',
        },
        {
            id: 6,
            link_title: 'Дизайн и Арт',
        },
        {
            id: 7,
            link_title: 'Аудио/Видео',
        },
        {
            id: 8,
            link_title: 'Мобильные приложения',
        },
        {
            id: 9,
            link_title: 'Переводы',
        },
        {
            id: 10,
            link_title: 'Менеджмент',
        },
        {
            id: 11,
            link_title: 'Программирование',
        },
        {
            id: 12,
            link_title: 'Инжиниринг',
        },
        {
            id: 13,
            link_title: 'Аутсорсинг и консалтинг',
        },
        {
            id: 14,
            link_title: 'Фотография',
        },
        {
            id: 15,
            link_title: 'Анимация и флеш',
        },
        {
            id: 16,
            link_title: 'Реклама и Маркетинг',
        },
        {
            id: 17,
            link_title: 'Оптимизация (SEO)',
        },
        {
            id: 18,
            link_title: 'Архитектура/Интерьер',
        },
        {
            id: 19,
            link_title: 'Полиграфия',
        },
        {
            id: 20,
            link_title: 'Сети и инфосистемы',
        },
    ];
    const top_performers = [
        {
            id: 1,
            performer_img: require('../../assets/img/performers_img1.jpeg'),
            performer_name: 'Artem Hakobjanyan',
            performer_country_name: 'Беларусь, Минск',
            performer_review_count: '400 отзывов',
            performer_portfolio_title: 'Полиграфический дизайн, 5 работ',
            performer_portfolio_info: 'Эффективный и яркий дизайн сайтов. 967+ успешных проектов. 16 лет опыта. Качественный дизайн принесет вам прибыль и проработает долго.',
            performer_portfolio_pics_box: [
                {
                    id: 1,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img1.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 2,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img2.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 3,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img3.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
            ]
        },
        {
            id: 2,
            performer_img: require('../../assets/img/performers_img3.jpeg'),
            performer_name: 'Artem Hakobjanyan',
            performer_country_name: 'Беларусь, Минск',
            performer_review_count: '400 отзывов',
            performer_portfolio_title: 'Полиграфический дизайн, 5 работ',
            performer_portfolio_info: 'Эффективный и яркий дизайн сайтов. 967+ успешных проектов. 16 лет опыта. Качественный дизайн принесет вам прибыль и проработает долго.',
            performer_portfolio_pics_box: [
                {
                    id: 1,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img1.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 2,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img2.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 3,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img3.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
            ]
        },
        {
            id: 3,
            performer_img: require('../../assets/img/performers_img4.jpg'),
            performer_name: 'Artem Hakobjanyan',
            performer_country_name: 'Беларусь, Минск',
            performer_review_count: '400 отзывов',
            performer_portfolio_title: 'Полиграфический дизайн, 5 работ',
            performer_portfolio_info: 'Эффективный и яркий дизайн сайтов. 967+ успешных проектов. 16 лет опыта. Качественный дизайн принесет вам прибыль и проработает долго.',
            performer_portfolio_pics_box: [
                {
                    id: 1,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img1.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 2,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img2.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 3,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img3.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
            ]
        },
        {
            id: 4,
            performer_img: require('../../assets/img/performers_img1.jpeg'),
            performer_name: 'Artem Hakobjanyan',
            performer_country_name: 'Беларусь, Минск',
            performer_review_count: '400 отзывов',
            performer_portfolio_title: 'Полиграфический дизайн, 5 работ',
            performer_portfolio_info: 'Эффективный и яркий дизайн сайтов. 967+ успешных проектов. 16 лет опыта. Качественный дизайн принесет вам прибыль и проработает долго.',
            performer_portfolio_pics_box: [
                {
                    id: 1,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img1.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 2,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img2.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 3,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img3.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
            ]
        },
        {
            id: 5,
            performer_img: require('../../assets/img/performers_img3.jpeg'),
            performer_name: 'Artem Hakobjanyan',
            performer_country_name: 'Беларусь, Минск',
            performer_review_count: '400 отзывов',
            performer_portfolio_title: 'Полиграфический дизайн, 5 работ',
            performer_portfolio_info: 'Эффективный и яркий дизайн сайтов. 967+ успешных проектов. 16 лет опыта. Качественный дизайн принесет вам прибыль и проработает долго.',
            performer_portfolio_pics_box: [
                {
                    id: 1,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img1.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 2,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img2.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 3,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img3.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
            ]
        },
        {
            id: 6,
            performer_img: require('../../assets/img/performers_img4.jpg'),
            performer_name: 'Artem Hakobjanyan',
            performer_country_name: 'Беларусь, Минск',
            performer_review_count: '400 отзывов',
            performer_portfolio_title: 'Полиграфический дизайн, 5 работ',
            performer_portfolio_info: 'Эффективный и яркий дизайн сайтов. 967+ успешных проектов. 16 лет опыта. Качественный дизайн принесет вам прибыль и проработает долго.',
            performer_portfolio_pics_box: [
                {
                    id: 1,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img1.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 2,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img2.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
                {
                    id: 3,
                    performer_portfolio_pic: require('../../assets/img/performers_work_img3.jpg'),
                    performer_portfolio_title: 'Rozamimoza'
                },
            ]
        },
    ]



    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        sl_set = {
            infinite: true,
            dots: false,
            arrows: false,
            fade: true,
            waitForAnimate: false,
            /*autoplaySpeed: 3000,
            autoplay: true,*/
            pauseOnHover: false,
            beforeChange: (oldIndex, newIndex) => {
                setSliding(true);
                // setCurPr(newIndex + 1);
            },
            afterChange: (newIndex) => {
                setSliding(false);
            }
        };
    }, []);

    const handleLoad = () => {
    };

    const handleScroll = (ev) => {
        let scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };

    const disableBodyScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableBodyScroll = () => {
        document.body.style.overflow = "auto";
    };

    const [showButton, setShowButton] = useState(false);

    const handleScroll2 = () => {
        if (window.scrollY > 700) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll2);
        return () => {
            window.removeEventListener('scroll', handleScroll2);
        };
    }, []);






    return (
        <>

            <div className='main_wrapper'>
                <Header disableBodyScroll={disableBodyScroll} enableBodyScroll={enableBodyScroll}  />
                <main>
                    <section className="top">
                        <div className='top_wrapper'>
                            <div className='top_main_video'>
                                <div id="js-for-sellers-header-video" className="for-sellers__header-video-wrapper">
                                    <video className="for-sellers__header-video" loop={true} autoPlay={true} muted="" controls={true}
                                           preload="metadata">
                                        <source src="https://cdn-edge.kwork.ru/images/for-sellers/header.webm?v=4"
                                                type="video/webm"/>
                                        <source src="https://cdn-edge.kwork.ru/images/for-sellers/header.mp4?v=4"
                                                type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                            <div className="top_info_main_box">
                                <h1 className='top_main_title'>
                                    Закажите услуги фрилансеров прямо сейчас
                                </h1>
                                <p className='top_info'>
                                    Быстро, просто и безопасно!
                                </p>
                                <button className='top_main_btn'>
                                    Разместить заказ
                                </button>
                            </div>
                            <div className='top_main_links_wrapper'>
                                <a href="https://apps.apple.com/ru/app/kwork/id1456387980" className='top_main_link'>
                                    <img src={require('../../assets/img/appstore_img.png')} alt=""/>
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=ru.kwork.app" className='top_main_link'>
                                    <img src={require('../../assets/img/google_img.png')} alt=""/>
                                </a>
                            </div>
                        </div>
                    </section>
                    <section className='choose_freelance'>
                        <div className='choose_freelance_wrapper'>
                            <h1 className='choose_freelance_title_main'>
                                Выберите фрилансера по специализации
                            </h1>
                            <h2 className='choose_freelance_title2'>
                                В этом разделе все специалисты прошли проверку и подтвердили личность
                            </h2>
                            <div className='choose_freelance_links_wrapper'>

                                {freelance_links_box.map((item, index) => {
                                    return (
                                        <a href="" className="choose_freelance_link" key={index}>
                                            {item.link_title}
                                        </a>
                                    );
                                })}

                            </div>
                        </div>
                    </section>
                    <section className="top_performers">
                        <div className="top_performers_wrapper">
                            <h1 className="top_performers_title_main">
                                Лучшие исполнители
                            </h1>
                            <h2 className='top_performers_title2'>
                                Успешно работают на AnyWork от пяти месяцев. Не меньше 8 отзывов, минимум 90% положительные
                            </h2>
                            <div className='top_performers_items_wrapper'>

                                {top_performers.map((item, index) => {
                                    return (
                                      <div className='top_performers_item'>
                                          <div className='top_performers_item_personal_info_box'>
                                              <div className='top_performers_item_img_info_box'>
                                                  <div className='top_performers_item_img'>
                                                      <img src={item.performer_img} alt=""/>
                                                  </div>
                                                  <div className='top_performers_item_info_wrapper'>
                                                      <p className='top_performers_item_name'>{item.performer_name}</p>
                                                      <p className='top_performers_item_country_name'>{item.performer_country_name}</p>
                                                  </div>
                                              </div>
                                               <div className='top_performers_item_review_box'>
                                                    <p className='top_performers_item_review_info'>
                                                        {item.performer_review_count}
                                                    </p>
                                               </div>
                                          </div>
                                          <div className='top_performers_item_portfolio_info_box'>
                                              <p className='top_performers_item_portfolio_info_box_title'>
                                                  {item.performer_portfolio_title}
                                              </p>
                                              <p className='top_performers_item_portfolio_info'>
                                                  {item.performer_portfolio_info}
                                              </p>
                                          </div>
                                          <div className='top_performers_item_portfolio_links_wrapper'>
                                              {item.performer_portfolio_pics_box.map((img_box, index) => {
                                                  return (
                                                      <a href="" className='top_performers_item_portfolio_link'>
                                                          <div className='top_performers_item_portfolio_link_img'>
                                                              <img src={img_box.performer_portfolio_pic} alt=""/>
                                                          </div>
                                                          <p className='top_performers_item_portfolio_link_title'>
                                                              {img_box.performer_portfolio_title}
                                                          </p>
                                                      </a>
                                                  );
                                              })}
                                          </div>

                                      </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                    <section className='about_us'>
                        <div className='about_us_wrapper'>
                            <h1 className='about_us_title'>
                                <span>AnyWork</span>
                                — крупнейшая русскоязычная биржа фриланса.
                            </h1>
                            <div className='about_us_items_wrapper'>
                                <div className='about_us_item'>
                                    <h2 className='about_us_item_title'>
                                        Вы заказчик, ищете фрилансеров
                                    </h2>
                                    <p className='about_us_item_info'>
                                        Хотите экономить деньги, время и нервы? Такая экономия возможна только тогда, когда freelance нацелен на результат. В этом плане Kwork, являясь маркетплейсом, имеет огромное преимущество перед любыми другими биржами исполнителей.
                                    </p>
                                    <p className='about_us_item_info'>
                                        Фрилансеры оформляют свои услуги в виде кворков, которые можно купить в один клик... То есть работа исполнителей продается как товар, а это экономит массу времени, денег и нервов. Идеально подходит для типовых задач: логотипы, баннеры, SEO и др.
                                    </p>
                                    <p className='about_us_item_info'>
                                        А главное каталог и поиск услуг настроены таким образом, что вы видите лучшие предложения на основе отзывов, качества и ответственности фрилансера. Если специалист работает плохо, его кворки уходят далеко вниз, мы не показываем их заказчикам. Поэтому на Kwork вы имеете дело с профессионалами, которые стараются выполнять задачи на пять.
                                    </p>
                                    <p className='about_us_item_info'>
                                        Кроме того, маркетплейс идеален для покупки фриланс услуг, когда у вас нет четкого ТЗ – вы просто выбираете тот кворк, который больше подходит. Если вы новичок или у вас нет опыта – начните именно с магазина, в нем разберется даже ребенок.
                                    </p>
                                    <a href="" className='about_us_item_link'>
                                        Создать проект и найти исполнителя
                                    </a>
                                </div>
                                <div className='about_us_item'>
                                    <h2 className='about_us_item_title'>
                                        Вы фрилансер, вам нужны заказы
                                    </h2>
                                    <p className='about_us_item_info'>
                                        Вы создаете задание, и оно становится доступно тысячам фрилансеров, которые могут отправить вам предложения. Остается только выбрать лучшее и начать работу. Такой подход к удаленной работе – идеальный вариант для решения больших и уникальных задач.
                                    </p>
                                    <p className='about_us_item_info'>
                                        Отличительной чертой биржи Kwork является качество заявок от фрилансеров. Если вы когда-нибудь работали на других фриланс площадках, то знаете, какая это боль получать множество нерелевантных предложений от исполнителей, которые даже не прочитали текст вашего задания. Но на Kwork всё по-другому – благодаря особому функционалу любому фрилансеру приходится ценить каждую отправленную заявку. Поэтому подавляющее большинство полученных предложений поступают от исполнителей, внимательно ознакомившихся с сутью задачи и сразу предлагающих то или иное решение.
                                    </p>
                                    <p className='about_us_item_info'>
                                        И еще один важный момент, на бирже фриланса и в магазине Kwork безопасная оплата заказов, которая проходит через систему - исполнитель получает деньги, только когда вы одобрите результат. В случае просрочки денежные средства можно вернуть на свой счет в один клик.
                                    </p>

                                    <a href="" className='about_us_item_link'>
                                        Стать фрилансером и найти работу
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>

            </div>
        </>

    )


}



