import { Link as LinkScroll } from 'react-scroll';
import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/css/header_style.css';
import {Link} from "react-router-dom";
import SearchIcon from  '../../../assets/svg/search_icon.svg'
import SearchIcon2 from  '../../../assets/svg/search_second_icon.svg'


const sl_set = {
    infinite: true,
    dots: false,
    arrows: false,
    fade: true,
    waitForAnimate: false,
    pauseOnHover: false,
    beforeChange: (oldIndex, newIndex) => {
        this.setState({
            cur_pr: newIndex + 1,
            sliding: true,
        });
    },
    afterChange: (newIndex) => {
        this.setState({
            sliding: false,
        });
    },
};

export default function Header(props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [show_get_consultation_popup, setShowGetConsultationPopup] = useState(false);
    const { disableBodyScroll } = props;
    const { enableBodyScroll } = props;


    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }


        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLoad = () => {};

    const handleScroll = (ev) => {
        const scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };



    return (
        <>
            <div className='header'>
                <div className='header_wrapper'>
                    <div className='header_logo_navigation_links_wrapper'>
                        <div className='header_logo'>
                            <Link to='/'  className='header_logo_link'>
                                <span className='header_logo_link1'>anywork</span>
                                <span className='header_logo_link2'>Фриланс биржа</span>
                            </Link>
                        </div>
                        <div className="headers_navigation_links_wrapper">
                            <Link  className="headers_navigation_link">
                                Проекты
                            </Link>
                            <Link className="headers_navigation_link">
                                Фрилансеры
                            </Link>
                            <Link  className="headers_navigation_link">
                                Для бизнеса
                            </Link>
                        </div>

                        <div className='header_general_search_input_icon_wrapper'>
                            <button className='header_general_search_input_icon2'>
                                <img src={SearchIcon2}/>
                            </button>
                            <input type="text" placeholder='Данные фрилансера' className='header_general_search_input_field'/>
                            <button className='header_general_search_input_icon'>
                                <img src={SearchIcon}/>
                            </button>
                        </div>
                    </div>
                    <div className='header_general_search_input_login_register_links_main_wrapper'>

                        <Link to='' className='header_login_link'>
                            Вход
                        </Link>
                        <Link to='' className='header_register_link'>
                            Регистрация
                        </Link>
                    </div>


                </div>
            </div>

        </>
    );
}
