import { Link as LinkScroll } from 'react-scroll';
import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/css/footer_style.css';
import {Link} from "react-router-dom";
import SocialLinkVk from  '../../../assets/svg/footer_social_link1.svg'
import SocialLinkTelegram from  '../../../assets/svg/footer_social_link2.svg'


const sl_set = {
    infinite: true,
    dots: false,
    arrows: false,
    fade: true,
    waitForAnimate: false,
    pauseOnHover: false,
    beforeChange: (oldIndex, newIndex) => {
        this.setState({
            cur_pr: newIndex + 1,
            sliding: true,
        });
    },
    afterChange: (newIndex) => {
        this.setState({
            sliding: false,
        });
    },
};

export default function Footer(props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [show_get_consultation_popup, setShowGetConsultationPopup] = useState(false);
    const { disableBodyScroll } = props;
    const { enableBodyScroll } = props;


    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }


        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLoad = () => {};

    const handleScroll = (ev) => {
        const scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };



    return (
        <>
            <div className='footer'>
                <div className="footer_wrapper">
                    <div className='footer_first_item'>
                        <Link to='/'  className='footer_logo_link'>
                            <span className='footer_logo_link1'>anywork</span>
                            <span className='footer_logo_link2'>Фриланс биржа</span>
                        </Link>
                        <div className='footer_links_items_wrapper'>
                            <div className='footer_links_item'>
                                <p className="footer_links_item_title">О Anywork</p>
                                <ul className="footer_links_item_ul_list">
                                    <li className="footer_links_item_ul_list_li">
                                       <a href="" className="footer_links_item_ul_list_link">О проекте</a>
                                   </li>
                                    <li className="footer_links_item_ul_list_li">
                                       <a href="" className="footer_links_item_ul_list_link">Пользовательское соглашение</a>
                                   </li>
                                    <li className="footer_links_item_ul_list_li">
                                        <a href="" className="footer_links_item_ul_list_link">Политика конфиденциальности</a>
                                    </li>
                                    <li className="footer_links_item_ul_list_li">
                                        <a href="" className="footer_links_item_ul_list_link">Способы оплаты</a>
                                    </li>
                                    <li className="footer_links_item_ul_list_li">
                                        <a href="" className="footer_links_item_ul_list_link">Карьера в Kwork</a>
                                    </li>

                                </ul>
                            </div>
                            <div className='footer_links_item'>
                                <p className="footer_links_item_title">Сообщество</p>
                                <ul className="footer_links_item_ul_list">
                                   <li className="footer_links_item_ul_list_li">
                                       <a href="" className="footer_links_item_ul_list_link">Блог</a>
                                   </li>
                                   <li className="footer_links_item_ul_list_li">
                                       <a href="" className="footer_links_item_ul_list_link">Партнерская программа</a>
                                   </li>
                                   <li className="footer_links_item_ul_list_li">
                                       <a href="" className="footer_links_item_ul_list_link">Кейсы</a>
                                   </li>
                                </ul>
                            </div>
                            <div className='footer_links_item'>
                                <p className="footer_links_item_title">Полезное</p>
                                <ul className="footer_links_item_ul_list">
                                    <li className="footer_links_item_ul_list_li">
                                       <a href="" className="footer_links_item_ul_list_link">Покупателям</a>
                                   </li>
                                    <li className="footer_links_item_ul_list_li">
                                        <a href="" className="footer_links_item_ul_list_link">Фрилансеру</a>
                                    </li>
                                    <li className="footer_links_item_ul_list_li">
                                        <a href="" className="footer_links_item_ul_list_link">Продавайте кворки за $!</a>
                                    </li>
                                    <li className="footer_links_item_ul_list_li">
                                        <a href="" className="footer_links_item_ul_list_link">Как зарабатывать на Kwork</a>
                                    </li>
                                    <li className="footer_links_item_ul_list_li">
                                        <a href="" className="footer_links_item_ul_list_link">Рубрики</a>
                                    </li>
                                    <li className="footer_links_item_ul_list_li">
                                        <a href="" className="footer_links_item_ul_list_link">Мобильное приложение</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='footer_links_item'>
                                <p className="footer_links_item_title">Помощь</p>
                                <ul className="footer_links_item_ul_list">
                                   <li className="footer_links_item_ul_list_li">
                                       <a href="" className="footer_links_item_ul_list_link">Вопрос — Ответ</a>
                                   </li>
                                   <li className="footer_links_item_ul_list_li">
                                       <a href="" className="footer_links_item_ul_list_link">Служба поддержк</a>
                                   </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='footer_second_item'>
                        <div className="footer_social_links_wrapper">
                            <a href="" className="footer_social_link">
                                <img src={SocialLinkVk} alt=""/>
                            </a>
                            <a href="" className="footer_social_link">
                                <img src={SocialLinkTelegram} alt=""/>
                            </a>
                        </div>
                        <div className='footer_google_app_store_links_wrapper'>
                            <a href="https://apps.apple.com/ru/app/kwork/id1456387980" className='footer_google_app_store_link'>
                                <img src={require('../../../assets/img/appstore_img.png')} alt=""/>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=ru.kwork.app" className='footer_google_app_store_link'>
                                <img src={require('../../../assets/img/google_img.png')} alt=""/>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
